
import { useRouter, useRoute } from "vue-router";
import {
  defineComponent,
  inject,
  ref,
  watch,
  reactive,
  Ref,
  computed,
} from "vue";
import { useI18n } from "vue-i18n";
import { Form } from "ant-design-vue";
import { AxiosStatic } from "axios";
import { notification } from "ant-design-vue";

import PhoneNumberComponent from "@hd2/common/src/components/PhoneNumber.vue";
import { SmsLogin } from "../../types";
import { PhoneNumber } from "@hd2/common/types";
import { RuleObject } from "ant-design-vue/es/form/interface";
import { notificationsList } from "../utils/const";
import { useStore } from "../store";
import { actions } from "../utils/const";
import { usePermissions } from "../composable/usePermissions";

interface Model {
  pesel: string;
  phoneNumber: PhoneNumber;
}

const useForm = Form.useForm;

export const LoginComponent = defineComponent({
  components: { "phone-number": PhoneNumberComponent },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const http = inject("http") as AxiosStatic;
    const { t } = useI18n();
    const store = useStore();
    const { hasPermission } = usePermissions();

    const mode = computed(() => store.state.runtimeConfig.mode);
    const appType = store.state.runtimeConfig.type;

    const valid: Ref<boolean> = ref(false);
    const processingToTheNextPage: Ref<boolean> = ref(false);
    const model: Model = reactive({
      pesel: "",
      phoneNumber: {
        number: "",
        prefix: "+48",
        pattern: "",
      },
    });

    const rules: Record<string, Array<RuleObject>> = reactive({
      pesel: [
        {
          type: "string",
          validator: (rule: RuleObject, value: string) => {
            return new Promise((resolve, reject) => {
              if (!value) {
                reject(t("LOGIN.LOGIN_REQUIRED"));
              } else {
                resolve();
              }
            });
          },
          trigger: ["change"],
        },
      ],
      phoneNumber: [
        {
          type: "string",
          validator: (rule: RuleObject, value: PhoneNumber) => {
            return new Promise((resolve, reject) => {
              if (!value.number || !value.prefix) {
                reject(t("LOGIN.PHONE_NUMBER_REQUIRED"));
              } else if (!value.number.match(value.pattern)) {
                reject(t("LOGIN.PHONE_NUMBER_INVALID"));
              } else {
                resolve();
              }
            });
          },
          trigger: ["change"],
        },
      ],
    });
    const form = useForm(model, rules);
    const formTemplate = ref();

    const onPhoneNumberChange = () => {
      formTemplate.value.validate("phoneNumber");
    };

    const register = () => router.push({ name: "Registration" });

    const login = async () => {
      processingToTheNextPage.value = true;

      const reqBody: SmsLogin = {
        phoneNumber: model.phoneNumber.prefix + model.phoneNumber.number,
        pesel: model.pesel,
      };

      notification.close(notificationsList.SESSION_TIMEOUT);

      try {
        await http.post("patient-portal/api/sms-login", reqBody);

        processingToTheNextPage.value = false;
        router.push({
          name: "Verify",
          params: {
            redirectedFrom: route.query.redirectedFrom?.toString() ?? "",
            pesel: model.pesel,
            phoneNumber: JSON.stringify(model.phoneNumber),
          },
        });
      } catch (err: any) {
        processingToTheNextPage.value = false;
        let errorMessage = `ERROR.4000`;
        if (err?.response.data.errorCode) {
          errorMessage = `ERROR.4${err.response.data.errorCode}`;
        }
        notification.open({
          message: t(errorMessage),
          class: "error",
        });
      }
    };

    watch(
      model,
      async () => {
        try {
          await form.validate();
          valid.value = true;
        } catch {
          valid.value = false;
        }
      },
      { deep: true }
    );

    return {
      mode,
      form,
      formTemplate,
      valid,
      processingToTheNextPage,
      model,
      hasPermission,
      actions,
      rules,
      onPhoneNumberChange,
      login,
      register,
      t,
      typeClass: computed(() => appType.toLowerCase().replace("_", "-")),
    };
  },
});
export default LoginComponent;
