import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26084dc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "login__box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_phone_number = _resolveComponent("phone-number")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass('login__logo login__logo--' + _ctx.typeClass)
      }, null, 2 /* CLASS */),
      _createVNode(_component_a_form, {
        class: "login__form",
        model: _ctx.model,
        rules: _ctx.rules,
        ref: "formTemplate",
        autocomplete: _ctx.mode === 'development' ? 'on' : 'off',
        onSubmit: _ctx.login
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, { name: "pesel" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                placeholder: _ctx.t('LOGIN.LOGIN'),
                value: _ctx.model.pesel,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.pesel) = $event))
              }, null, 8 /* PROPS */, ["placeholder", "value"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_a_form_item, {
            name: "phoneNumber",
            autoLink: false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_phone_number, {
                placeholder: _ctx.t('PHONE_NUMBER.NUMBER'),
                onChange: _ctx.onPhoneNumberChange,
                value: _ctx.model.phoneNumber,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.phoneNumber) = $event))
              }, null, 8 /* PROPS */, ["placeholder", "onChange", "value"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_a_form_item, { style: {"margin-bottom":"32px","margin-top":"48px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                class: "login__submit",
                type: "primary",
                loading: _ctx.processingToTheNextPage,
                "html-type": "submit",
                disabled: !_ctx.valid
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("LOGIN.SIGN_IN")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["loading", "disabled"]),
              (_ctx.hasPermission(_ctx.actions.REGISTRATION))
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 0,
                    class: "login__registration",
                    type: "default",
                    onClick: _ctx.register
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("LOGIN.SIGN_UP")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model", "rules", "autocomplete", "onSubmit"])
    ])
  ]))
}